import { Component, OnInit, NgModuleFactoryLoader } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { ExerciseTask } from '../../interfaces/exercise-task';
import { FormType } from '../../configuration/form-type';
import { Router } from '@angular/router';


@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})

export class ExerciseComponent implements OnInit {


 
  public task: ExerciseTask;
  public textfield: string;
  public resultat: number;
  public ergebnis: number;
  public actualTaskNumber: number;
  public actualPunkte: number;
  public numberOperation: number;
  public lastArg1: number;
	public lastOperand: string;
	public lastArg2: number;
  public lastResultat: number;
  public lastTextfield: string;
  public feedback: string;
  public name: string;
  public summandMin: number;
  public summandMax: number;
  public minuendMin: number;
  public minuendMax: number;
  public subtrahendMin: number;
  public subtrahendMax: number;
  public faktorMin: number;
  public faktorMax: number;
  public divisorMin: number;
  public divisorMax: number;

  public results: [];
  
  private values: FormType[];


  constructor(
    private coreService: CoreService,
    private router: Router,) {}

//    ngOnDestroy(){
//     window.location.reload();
//   }
    
    ngOnInit() {
    this.values = this.coreService.getConfig();
    this.actualTaskNumber = 0;
    this.actualPunkte = 0;
    this.textfield = '';

    this.name = String(this.values[1].value);
    this.summandMin = Number(this.values[2].value);
    this.summandMax = Number(this.values[3].value);
    this.minuendMin = Number(this.values[4].value);
    this.minuendMax = Number(this.values[5].value);
    this.subtrahendMin = Number(this.values[6].value);
    this.subtrahendMax = Number(this.values[7].value);
    this.faktorMin = Number(this.values[8].value);
    this.faktorMax = Number(this.values[9].value);
    this.divisorMin = Number(this.values[10].value);
    this.divisorMax = Number(this.values[11].value);
    this.numberOperation = Number(this.values[12].value);
    sessionStorage.setItem('Name', (this.name));
    

    this.createExercise();
   }

    
 // hier wird die Aufabe erstellt, zuerst Wahl der Operation, dann die Aufgabe per Zufallsgenerator erstellt
 public createExercise() {
      
  // 1 für Addition, 2 für Subtraktion, 3 für Multiplikation 4 für Divsion
  let operation = Math.round(Math.random() * this.numberOperation + 0.5);
 
  // nach Entscheid der Operation zwischen 1 und 4 werden je nach Operation zwei Zufallszahlen gebildet
  // bei der Subtraktion wird verhindert, dass das Resultat negativ wird (könnte noch als Option eingebaut werden )
  // bei der Division wird zuerst der Divisor gebildet, welcher am Ende mit dem Dividenden multipliziert wird, damit es eine Ganzzahl als Ergebnis gibt
  switch (operation) {
    case 1:
    let ersterSummand = Math.round(Math.random() * (this.summandMax - this.summandMin)) + this.summandMin;
    let zweiterSummand = Math.round(Math.random() * (this.summandMax - this.summandMin)) + this.summandMin;
      this.task = new ExerciseTask(ersterSummand, '+', zweiterSummand);
      this.ergebnis = ersterSummand + zweiterSummand;
    break;
    
    case 2:
      let minuend = Math.round(Math.random() * (this.minuendMax - this.minuendMin)) + this.minuendMin;
      let subtrahend = Math.round(Math.random() * (this.subtrahendMax - this.subtrahendMin)) + this.subtrahendMin;
      if (subtrahend > minuend) {
        let zwischenSpeicher = minuend;
        minuend = subtrahend;
        subtrahend = zwischenSpeicher;
      }
      this.task = new ExerciseTask(minuend, '-', subtrahend);
      this.ergebnis = minuend - subtrahend;
    break;
    
    case 3:
      let ersterFaktor = Math.round(Math.random() * (this.faktorMax - this.faktorMin) ) + this.faktorMin;
      let zweiterFaktor = Math.round(Math.random() * (this.faktorMax - this.faktorMin)) + this.faktorMin;
      this.task = new ExerciseTask(ersterFaktor, '*', zweiterFaktor);
      this.ergebnis = ersterFaktor * zweiterFaktor;
    break;
    
    case 4:
      let divisor = Math.round(Math.random() * (this.divisorMax - this.divisorMin)) + this.divisorMin;
      let dividend = Math.round(Math.random() * (this.divisorMax - this.divisorMin)) * divisor;
      this.task = new ExerciseTask(dividend, ':', divisor);
      this.ergebnis = dividend / divisor;
    break;
    
    default:
      alert("Keine Übereinstimmung");
  }

  // notwendige Werte werden im sessionStorage gespeichert
  sessionStorage.setItem('aktuelle Punktzahl', JSON.stringify(this.actualPunkte));
  sessionStorage.setItem('aktuelle Nummer', JSON.stringify(this.actualTaskNumber));
}

// hier wird nach der Eingabe des Ergebnisses dieses nach click oder Return kontrolliert, die TaskNumber um 1 erhöht, und die nächste Aufgabe aufgerufen
  // zudem werden je nachdem Pluspunkte oder Minuspunkte verrechnet und die aktuelle Note neu berechnet
  // es wird ein Feedback gegeben, dies über 1,5 Sekunden, grün bei richtig gelöster Aufgabe, rot bei falsch gelöster Aufgabe
  public buttonClick() {
    this.lastArg1 = this.task.arg1;
	  this.lastOperand = this.task.operand;
	  this.lastArg2 = this.task.arg2;
    this.lastResultat = this.ergebnis;
    this.lastTextfield = this.textfield;
        
    if (parseFloat(this.textfield) === this.ergebnis) {
      this.actualPunkte = this.actualPunkte  + 1; 
      this.actualTaskNumber = this.actualTaskNumber + 1;
      this.feedback = 'Richtig';
      var node = document.querySelector('#richtig1') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#falsch1') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#richtig2') as HTMLElement;
      node.style.visibility = 'visible';
      var node = document.querySelector('#falsch2') as HTMLElement;
      node.style.visibility = 'hidden';
      setTimeout(this.ausblendenFeedback,1500);
      
      } else {
        this.actualTaskNumber = this.actualTaskNumber + 1;
        this.feedback = 'Falsch: - ';
        var node = document.querySelector('#richtig1') as HTMLElement;
        node.style.display = 'none';
        var node = document.querySelector('#falsch1') as HTMLElement;
        node.style.display = 'block';
        var node = document.querySelector('#richtig2') as HTMLElement;
        node.style.visibility = 'hidden';
        var node = document.querySelector('#falsch2') as HTMLElement;
        node.style.visibility = 'visible';
        setTimeout(this.ausblendenFeedback,1500);
      }
    
   
    this.createExercise();
    this.textfield = '';
  }

  // Feedback wird nach einer bestimmten Zeit ausgeblendet
  public ausblendenFeedback() {
    var node = document.querySelector('#richtig1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#falsch1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#richtig2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#falsch2') as HTMLElement;
    node.style.visibility = 'hidden';
  }

       
}
