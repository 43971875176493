import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ExerciseTask } from '../interfaces/exercise-task';
import { Router } from '@angular/router';

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelsComponent implements OnInit {

  public task: ExerciseTask;
  public textfield: string;
  public resultat: number;
  public ergebnis: number;
  public actualTaskNumber: number;
  public actualCorrect: number;
  public actualFalse: number;
  public numberOperation: number;
  public lastArg1: number;
	public lastOperand: string;
	public lastArg2: number;
  public lastResultat: number;
  public lastTextfield: string;
  public feedback: string;
  
  private summandMin: number;
  private summandMax: number;
  private minuendMin: number;
  private minuendMax: number;
  private subtrahendMin: number;
  private subtrahendMax: number;
  private faktorMin: number;
  private faktorMax: number;
  private divisorMin: number;
  private divisorMax: number;

  public zahlenraum: string;
  public operationen: string;
  
  constructor() { }

  ngOnInit()  {  }


  public l1()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 1;
    this.summandMin = 1;
    this.summandMax = 5;
    this.zahlenraum = 'bis 10';
    this.operationen = 'Addition';
    this.createExercise();
  }

  public l2()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 1;
    this.summandMin = 3;
    this.summandMax = 10;
    this.zahlenraum = 'bis 20';
    this.operationen = 'Addition';
    this.createExercise();
  }

  public l3()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 1;
    this.summandMax = 30;
    this.minuendMin = 1;
    this.minuendMax = 30;
    this.subtrahendMin = 1;
    this.subtrahendMax = 14;
    this.zahlenraum = 'bis 30';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l4()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 4;
    this.summandMax = 25;
    this.minuendMin = 2;
    this.minuendMax = 50;
    this.subtrahendMin = 2;
    this.subtrahendMax = 50;
    this.zahlenraum = 'bis 50';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l5()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 3;
    this.summandMax = 100;
    this.minuendMin = 3;
    this.minuendMax = 100;
    this.subtrahendMin = 3;
    this.subtrahendMax = 34;
    this.zahlenraum = 'bis 100';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l6()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 3;
    this.summandMin = 6;
    this.summandMax = 50;
    this.minuendMin = 4;
    this.minuendMax = 100;
    this.subtrahendMin = 4;
    this.subtrahendMax = 100;
    this.faktorMin = 1;
    this.faktorMax = 8;
    this.zahlenraum = 'bis 100';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x8)';
    this.createExercise();
  }

  public l7()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 6;
    this.summandMax = 100;
    this.minuendMin = 5;
    this.minuendMax = 200;
    this.subtrahendMin = 5;
    this.subtrahendMax = 200;
    this.zahlenraum = 'bis 200';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l8()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 3;
    this.summandMin = 7;
    this.summandMax = 100;
    this.minuendMin = 6;
    this.minuendMax = 200;
    this.subtrahendMin = 6;
    this.subtrahendMax = 200;
    this.faktorMin = 1;
    this.faktorMax = 8;
    this.zahlenraum = 'bis 200';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x8)';
    this.createExercise();
  }

  public l9()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 8;
    this.summandMax = 25;
    this.minuendMin = 7;
    this.minuendMax = 250;
    this.subtrahendMin = 7;
    this.subtrahendMax = 250;
    this.zahlenraum = 'bis 250';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l10()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 3;
    this.summandMin = 9;
    this.summandMax = 125;
    this.minuendMin = 8;
    this.minuendMax = 250;
    this.subtrahendMin = 8;
    this.subtrahendMax = 250;
    this.faktorMin = 1;
    this.faktorMax = 12;
    this.zahlenraum = 'bis 250';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x12)';
    this.createExercise();
  }

  public l11()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 10;
    this.summandMax = 250;
    this.minuendMin = 9;
    this.minuendMax = 500;
    this.subtrahendMin = 9;
    this.subtrahendMax = 500;
    this.zahlenraum = 'bis 500';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l12()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 3;
    this.summandMin = 12;
    this.summandMax = 250;
    this.minuendMin = 10;
    this.minuendMax = 500;
    this.subtrahendMin = 10;
    this.subtrahendMax = 500;
    this.faktorMin = 1;
    this.faktorMax = 15;
    this.zahlenraum = 'bis 500';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x15)';
    this.createExercise();
  }

  public l13()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 15;
    this.summandMax = 500;
    this.minuendMin = 12;
    this.minuendMax = 1000;
    this.subtrahendMin = 12;
    this.subtrahendMax = 1000;
    this.zahlenraum = 'bis 1000';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l14()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 3;
    this.summandMin = 16;
    this.summandMax = 500;
    this.minuendMin = 14;
    this.minuendMax = 1000;
    this.subtrahendMin = 14;
    this.subtrahendMax = 1000;
    this.faktorMin = 2;
    this.faktorMax = 10;
    this.zahlenraum = 'bis 1000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x10)';
    this.createExercise();
  }

  public l15()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 4;
    this.summandMin = 18;
    this.summandMax = 500;
    this.minuendMin = 16;
    this.minuendMax = 1000;
    this.subtrahendMin = 16;
    this.subtrahendMax = 1000;
    this.faktorMin = 3;
    this.faktorMax = 15;
    this.divisorMin = 2;
    this.divisorMax = 10;
    this.zahlenraum = 'bis 1000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x15) / Division (bis :10)';
    this.createExercise();
  }

  public l16()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 4;
    this.summandMin = 20;
    this.summandMax = 500;
    this.minuendMin = 18;
    this.minuendMax = 1000;
    this.subtrahendMin = 18;
    this.subtrahendMax = 1000;
    this.faktorMin = 4;
    this.faktorMax = 20;
    this.divisorMin = 2;
    this.divisorMax = 15;
    this.zahlenraum = 'bis 1000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x20) / Division (bis :15)';
    this.createExercise();
  }

  public l17()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 20;
    this.summandMax = 1000;
    this.minuendMin = 20;
    this.minuendMax = 2000;
    this.subtrahendMin = 20;
    this.subtrahendMax = 2000;
    this.zahlenraum = 'bis 2000';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l18()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 3;
    this.summandMin = 25;
    this.summandMax = 1000;
    this.minuendMin = 25;
    this.minuendMax = 2000;
    this.subtrahendMin = 25;
    this.subtrahendMax = 2000;
    this.faktorMin = 2;
    this.faktorMax = 25;
    this.zahlenraum = 'bis 2000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x25)';
    this.createExercise();
  }

  public l19()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 4;
    this.summandMin = 30;
    this.summandMax = 1000;
    this.minuendMin = 30;
    this.minuendMax = 2000;
    this.subtrahendMin = 30;
    this.subtrahendMax = 2000;
    this.faktorMin = 3;
    this.faktorMax = 30;
    this.divisorMin = 2;
    this.divisorMax = 20;
    this.zahlenraum = 'bis 2000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x30) / Division (bis :20)';
    this.createExercise();
  }

  public l20()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 4;
    this.summandMin = 40;
    this.summandMax = 1000;
    this.minuendMin = 40;
    this.minuendMax = 2000;
    this.subtrahendMin = 40;
    this.subtrahendMax = 2000;
    this.faktorMin = 3;
    this.faktorMax = 35;
    this.divisorMin = 2;
    this.divisorMax = 30;
    this.zahlenraum = 'bis 2000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x35) / Division (bis :30)';
    this.createExercise();
  }

  public l21()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 50;
    this.summandMax = 2500;
    this.minuendMin = 50;
    this.minuendMax = 5000;
    this.subtrahendMin = 50;
    this.subtrahendMax = 5000;
    this.zahlenraum = 'bis 5000';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l22()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 4;
    this.summandMin = 100;
    this.summandMax = 2500;
    this.minuendMin = 100;
    this.minuendMax = 5000;
    this.subtrahendMin = 100;
    this.subtrahendMax = 5000;
    this.faktorMin = 3;
    this.faktorMax = 40;
    this.divisorMin = 3;
    this.divisorMax = 35;
    this.zahlenraum = 'bis 5000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x40) / Division (bis :35)';
    this.createExercise();
  }

  public l23()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 2;
    this.summandMin = 200;
    this.summandMax = 5000;
    this.minuendMin = 150;
    this.minuendMax = 10000;
    this.subtrahendMin = 150;
    this.subtrahendMax = 10000;
    this.zahlenraum = 'bis 10000';
    this.operationen = 'Addition / Subtraktion';
    this.createExercise();
  }

  public l24()   {
    this.actualCorrect = 0;
    this.actualFalse = 0;
    this.actualTaskNumber = 0;
    this.numberOperation = 4;
    this.summandMin = 500;
    this.summandMax = 5000;
    this.minuendMin = 500;
    this.minuendMax = 10000;
    this.subtrahendMin = 500;
    this.subtrahendMax = 10000;
    this.faktorMin = 2;
    this.faktorMax = 50;
    this.divisorMin = 3;
    this.divisorMax = 50;
    this.zahlenraum = 'bis 10000';
    this.operationen = 'Addition / Subtraktion / Multiplikation (bis x50) / Division (bis :50)';
    this.createExercise();
  }

// hier wird die Aufabe erstellt, zuerst Wahl der Operation, dann die Aufgabe per Zufallsgenerator erstellt
public createExercise() {
        
  // 1 für Addition, 2 für Subtraktion, 3 für Multiplikation 4 für Divsion
  let operation = Math.round(Math.random() * this.numberOperation + 0.5);
 
  // nach Entscheid der Operation zwischen 1 und 4 werden je nach Operation zwei Zufallszahlen gebildet
  // bei der Subtraktion wird verhindert, dass das Resultat negativ wird (könnte noch als Option eingebaut werden )
  // bei der Division wird zuerst der Divisor gebildet, welcher am Ende mit dem Dividenden multipliziert wird, damit es eine Ganzzahl als Ergebnis gibt
  switch (operation) {
    case 1:
    let ersterSummand = Math.round(Math.random() * (this.summandMax - this.summandMin)) + this.summandMin;
    let zweiterSummand = Math.round(Math.random() * (this.summandMax - this.summandMin)) + this.summandMin;
      this.task = new ExerciseTask(ersterSummand, '+', zweiterSummand);
      this.ergebnis = ersterSummand + zweiterSummand;
    break;
    
    case 2:
      let minuend = Math.round(Math.random() * (this.minuendMax - this.minuendMin)) + this.minuendMin;
      let subtrahend = Math.round(Math.random() * (this.subtrahendMax - this.subtrahendMin)) + this.subtrahendMin;
      if (subtrahend > minuend) {
        let zwischenSpeicher = minuend;
        minuend = subtrahend;
        subtrahend = zwischenSpeicher;
      }
      this.task = new ExerciseTask(minuend, '-', subtrahend);
      this.ergebnis = minuend - subtrahend;
    break;
    
    case 3:
      let ersterFaktor = Math.round(Math.random() * (this.faktorMax - this.faktorMin) ) + this.faktorMin;
      let zweiterFaktor = Math.round(Math.random() * (this.faktorMax - this.faktorMin)) + this.faktorMin;
      this.task = new ExerciseTask(ersterFaktor, '*', zweiterFaktor);
      this.ergebnis = ersterFaktor * zweiterFaktor;
    break;
    
    case 4:
      let divisor = Math.round(Math.random() * (this.divisorMax - this.divisorMin)) + this.divisorMin;
      let dividend = Math.round(Math.random() * (this.divisorMax - this.divisorMin)) * divisor;
      this.task = new ExerciseTask(dividend, ':', divisor);
      this.ergebnis = dividend / divisor;
    break;
    
    default:
      alert("Keine Übereinstimmung");
  }

}

// hier wird nach der Eingabe des Ergebnisses dieses nach click oder Return kontrolliert, die TaskNumber um 1 erhöht, und die nächste Aufgabe aufgerufen
  // zudem werden je nachdem Pluspunkte oder Minuspunkte verrechnet und die aktuelle Note neu berechnet
  // es wird ein Feedback gegeben, dies über 1,5 Sekunden, grün bei richtig gelöster Aufgabe, rot bei falsch gelöster Aufgabe
  public buttonClick() {
    this.lastArg1 = this.task.arg1;
    this.lastOperand = this.task.operand;
    this.lastArg2 = this.task.arg2;
    this.lastResultat = this.ergebnis;
    this.lastTextfield = this.textfield;
        
    if (parseFloat(this.textfield) === this.ergebnis) {
      this.actualCorrect = this.actualCorrect  + 1; 
      this.actualTaskNumber = this.actualTaskNumber + 1;
      this.feedback = 'Richtig';
      var node = document.querySelector('#richtig1') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#falsch1') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#richtig2') as HTMLElement;
      node.style.visibility = 'visible';
      var node = document.querySelector('#falsch2') as HTMLElement;
      node.style.visibility = 'hidden';
      setTimeout(this.ausblendenFeedback,1500);
      
      } else {
        this.actualTaskNumber = this.actualTaskNumber + 1;
        this.actualFalse = this.actualFalse + 1;
        this.feedback = 'Falsch: - ';
        var node = document.querySelector('#richtig1') as HTMLElement;
        node.style.display = 'none';
        var node = document.querySelector('#falsch1') as HTMLElement;
        node.style.display = 'block';
        var node = document.querySelector('#richtig2') as HTMLElement;
        node.style.visibility = 'hidden';
        var node = document.querySelector('#falsch2') as HTMLElement;
        node.style.visibility = 'visible';
        setTimeout(this.ausblendenFeedback,1500);
      }
    
   
    this.createExercise();
    this.textfield = '';
  }

  // Feedback wird nach einer bestimmten Zeit ausgeblendet
  public ausblendenFeedback() {
    var node = document.querySelector('#richtig1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#falsch1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#richtig2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#falsch2') as HTMLElement;
    node.style.visibility = 'hidden';
  }

       
}

