import { Component, OnInit } from '@angular/core';
import { FormType } from '../../configuration/form-type';
import { Router, Params } from '@angular/router';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']

})

export class ConfigurationComponent implements OnInit {

  public values: FormType[];

  constructor(
    private router: Router,
    private coreService: CoreService) { }

    ngOnInit() {
    this.values = this.coreService.getConfig();
    
  }

  public buttonClick() {
    this.coreService.saveConfig(this.values);
    // console.log('Values: ' + JSON.stringify(this.values));

    this.router.navigate(['/exercise']);
    
    
  }

}
