import { Component, OnInit } from '@angular/core';
import { ExerciseTask } from '../../interfaces/exercise-task';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reihen6',
  templateUrl: './reihen6.component.html',
  styleUrls: ['./reihen6.component.scss']
})
export class Reihen6Component implements OnInit {

  public task: ExerciseTask;
  public textfield: string;
  public resultat: number;
  public ergebnis: number;
  public actualTaskNumber: number;
  public actualPunkte: number;
  public reihe: number;
  public faktor: number;
  public anzahlAufgaben: number;

  public lastArg1: number;
	public lastOperand: string;
	public lastArg2: number;
  public lastResultat: number;
  public lastTextfield: string;
  public feedback: string;


  constructor(
    private router: Router) {
    }

  ngOnInit() {
  
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.anzahlAufgaben = 21;
  this.reihe2();
    
  }
  
  
  
  public createExercise() {
       
    // 1 bis 10
    let faktor = Math.round(Math.random() * 10 + 0.5);
    // console.log('Gewählter Faktor: ' + faktor)
   
    var node = document.querySelector('#cat') as HTMLElement;
    node.style.display = 'block';
   
    switch (faktor) {
      case 1:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 2:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 3:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 4:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 5:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 6:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 7:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 8:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 9:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;

      case 10:
        this.task = new ExerciseTask(faktor, 'mal' ,this.reihe);
        this.ergebnis = faktor * this.reihe;
      break;
      
      default:
        alert("Keine Übereinstimmung");
    }
}
  

public level1(){
  this.router.navigate(['/reihen1']);
}

public level2(){
  this.router.navigate(['/reihen2']);
}

public level6(){
  this.router.navigate(['/reihen6']);
}

public anzahlAufgaben10()   {
  this.anzahlAufgaben = 10;
  sessionStorage.setItem('Anzahl Aufgaben', JSON.stringify(this.anzahlAufgaben));  
  
  }

public anzahlAufgaben20()   {
  this.anzahlAufgaben = 20;
  sessionStorage.setItem('Anzahl Aufgaben', JSON.stringify(this.anzahlAufgaben));  
   
  }

public anzahlAufgaben30()   {
  this.anzahlAufgaben = 30;
  sessionStorage.setItem('Anzahl Aufgaben', JSON.stringify(this.anzahlAufgaben));  
 
  }

public anzahlAufgaben40()   {
  this.anzahlAufgaben = 40;
  sessionStorage.setItem('Anzahl Aufgaben', JSON.stringify(this.anzahlAufgaben));  
   
  }

public anzahlAufgaben50()   {
  this.anzahlAufgaben = 50;
  sessionStorage.setItem('Anzahl Aufgaben', JSON.stringify(this.anzahlAufgaben));  
  
  }

public reihe2()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 2;
  this.createExercise();
}

public reihe3()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 3;
  this.createExercise();
}

public reihe4()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 4;
  this.createExercise();
}

public reihe5()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 5;
  this.createExercise();
}

public reihe6()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 6;
  this.createExercise();
}

public reihe7()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 7;
  this.createExercise();
}

public reihe8()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 8;
  this.createExercise();
}

public reihe9()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 9;
  this.createExercise();
}

public reihe10()   {
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfield = '';
  this.reihe = 10;
  this.createExercise();
}



  public buttonClick() {
    this.lastArg1 = this.task.arg1;
	  this.lastOperand = this.task.operand;
	  this.lastArg2 = this.task.arg2;
    this.lastResultat = this.ergebnis;
    this.lastTextfield = this.textfield;
        
        
    if (parseFloat(this.textfield) === this.ergebnis) {
      this.actualPunkte = this.actualPunkte  + 1; 
      this.actualTaskNumber = this.actualTaskNumber + 1;
      this.feedback = 'Richtig';
      var node = document.querySelector('#richtig1') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#falsch1') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#richtig2') as HTMLElement;
      node.style.visibility = 'visible';
      var node = document.querySelector('#falsch2') as HTMLElement;
      node.style.visibility = 'hidden';
      setTimeout(this.ausblendenFeedback,1500);
      
      } else {
        this.actualTaskNumber = this.actualTaskNumber + 1;
        this.feedback = 'Falsch: - ';
        var node = document.querySelector('#richtig1') as HTMLElement;
        node.style.display = 'none';
        var node = document.querySelector('#falsch1') as HTMLElement;
        node.style.display = 'block';
        var node = document.querySelector('#richtig2') as HTMLElement;
        node.style.visibility = 'hidden';
        var node = document.querySelector('#falsch2') as HTMLElement;
        node.style.visibility = 'visible';
        setTimeout(this.ausblendenFeedback,1500);
      }
    
   
if (this.anzahlAufgaben > 1) {
      this.anzahlAufgaben = this.anzahlAufgaben -1;
      this.createExercise();
      this.textfield = '';
    }
    else {
      // notwendige Werte werden im sessionStorage gespeichert
      sessionStorage.setItem('aktuelle Punktzahl', JSON.stringify(this.actualPunkte));
      sessionStorage.setItem('aktuelle Nummer', JSON.stringify(this.actualTaskNumber));
      sessionStorage.setItem('aktuelle Reihe', JSON.stringify(this.reihe));
        
      this.router.navigate(['/inforeihen6']);
    }
  }

  
  // Feedback wird nach einer bestimmten Zeit ausgeblendet
  public ausblendenFeedback() {
    var node = document.querySelector('#richtig1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#falsch1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#richtig2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#falsch2') as HTMLElement;
    node.style.visibility = 'hidden';
  }

       
} 
