import { Component, OnInit } from '@angular/core';
import { ExerciseTask } from '../../interfaces/exercise-task';
import { FormType } from '../../configuration/form-type';
import { Router } from '@angular/router';
import { Testr2Service } from '../../services/testr2.service';

@Component({
  selector: 'app-exercisetestr2',
  templateUrl: './exercisetestr2.component.html',
  styleUrls: ['./exercisetestr2.component.scss']
})
export class Exercisetestr2Component implements OnInit {

  public task: ExerciseTask;
  public textfield: string;
  public resultat: number;
  public ergebnis: number;
  public actualTaskNumber: number;
  public punkte: number;
  public plusPunkte: number;
  public minusPunkte: number;
  public actualPunkte: number;
  public numberOperation: number;
  public lastArg1: number;
	public lastOperand: string;
	public lastArg2: number;
  public lastResultat: number;
  public lastTextfield: string;
  public feedback: string
  public name: string;
  public counter: number;
  public punkteNoteSechs: number;
  public punkteNoteVier: number;
  public summandMin: number;
  public summandMax: number;
  public minuendMin: number;
  public minuendMax: number;
  public subtrahendMin: number;
  public subtrahendMax: number;
  public faktorMin: number;
  public faktorMax: number;
  public divisorMin: number;
  public divisorMax: number;

  public controlPunkte: number;
  public controlNote: number;
    
  
  private valuesr2: FormType[]; 

  constructor(
    private router: Router,
    private testr2Service: Testr2Service) { }

  
    ngOnInit() {
    this.valuesr2 = this.testr2Service.getConfig();
    this.actualTaskNumber = 0;
    this.actualPunkte = 0;
    this.textfield = '';
    this.counter = 0;
    this.plusPunkte = 0;
    this.controlPunkte = 0;
    this.minusPunkte = 0;
    this.punkteNoteSechs = 0;
    this.punkteNoteVier = 0;
    this.createExerciseWrapper();
       
  }
  
  // Name sollte eingetragen sein; wird im Moment nicht kontrolliert
  // Name wird im sessionStorage gespeichert
  public startTest() {
    sessionStorage.setItem('Name', this.name);
    this.startBereichAusblenden();
    this.bereichRechnungEinblenden();
    this.bereichResultatEinblenden();
    this.counterStart();
    this.startBalken();
  }
  
  // Bereich Start ausblenden
  public startBereichAusblenden() {
    var node = document.querySelector('#start') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#start1') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#start2') as HTMLElement;
    node.style.visibility = 'hidden';
  }

  // Bereich Rechnung einblenden
  public bereichRechnungEinblenden() {
    var node = document.querySelector('#rechnung') as HTMLElement;
    node.style.display = 'block';
    var node = document.querySelector('#rechnung1') as HTMLElement;
    node.style.visibility = 'visible';
    var node = document.querySelector('#rechnung2') as HTMLElement;
    node.style.visibility = 'visible';
  }

  // Bereich Resultat einblenden
  public bereichResultatEinblenden() {
    var node = document.querySelector('#resultat') as HTMLElement;
    node.style.display = 'block';
    var node = document.querySelector('#resultat1') as HTMLElement;
    node.style.visibility = 'visible';
    var node = document.querySelector('#resultat2') as HTMLElement;
    node.style.visibility = 'visible';
    var node = document.querySelector('#resultat3') as HTMLElement;
    node.style.visibility = 'visible';
  }

    // Countdown starten
    public counterStart() {

      var node = document.querySelector('#counterEbene') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#counter') as HTMLElement;
      node.style.visibility = 'visible';
  
      var i = this.counter;
      var myinterval = setInterval(() => { 
        // Umrechnung der Sekunden in Minuten und Sekunden
        var  timenew = Math.floor(i / 60 )+' : '+Math.floor((i % 60));
        document.getElementById("counter").innerHTML = timenew;
        if (i === 0) {
          this.berechnungNote();
          sessionStorage.setItem('definitive Punkte', JSON.stringify(this.controlPunkte));
          clearInterval(myinterval );
          this.router.navigate(['/resultstestr2']);
        }
        else {
          i--;
        }
      }, 1000);
    }
  
    // Countdown Balken starten, Progress negativ 
  public startBalken() {
  
    var node = document.querySelector('#balken') as HTMLElement;
    node.style.visibility = 'visible';
  
    var i = 0;
    if (i == 0) {
      var elem = document.getElementById("balken");
      var width = 100;
      var faktor = this.counter/100*1000;
      var id = setInterval(frame, faktor);
      function frame() {
        if (width <=0) {
          clearInterval(id);
         i = 0 ;
        } else {
          width--;
          elem.style.width = width + "%";
          var node = document.querySelector('#balken') as HTMLElement;
          node.style.display = 'block';
          }
        }
      }
    }

  // hier wird die nächste Aufabe erstellt, mit Wrapper
  public createExerciseWrapper() {

    this.createExercise(Number(this.valuesr2[0].value), Number(this.valuesr2[1].value), Number(this.valuesr2[2].value), Number(this.valuesr2[3].value), Number(this.valuesr2[4].value), 
                        Number(this.valuesr2[5].value), Number(this.valuesr2[6].value), Number(this.valuesr2[7].value), Number(this.valuesr2[8].value), Number(this.valuesr2[9].value), 
                        Number(this.valuesr2[10].value), Number(this.valuesr2[11].value), Number(this.valuesr2[12].value), Number(this.valuesr2[13].value), Number(this.valuesr2[14].value), Number(this.valuesr2[15].value)); 
    
  }
    
  // hier wird die Aufabe erstellt, zuerst Wahl der Operation, dann die Aufgabe per Zufallsgenerator erstellt
  public createExercise(counter: number, plusPunkte: number, minusPunkte: number, punkteNoteSechs: number, punkteNoteVier: number, summandMin: number, summandMax: number, minuendMin: number, minuendMax: number, subtrahendMin: number, subtrahendMax: number, faktorMin: number, faktorMax: number, divisorMin: number, divisorMax: number, numberOperation: number) {
      
    // Zuordnung aller Variablen, damit sie in anderen Blöcken verwendet werden können
    this.counter = counter;
    this.plusPunkte = plusPunkte;
    this.minusPunkte = minusPunkte;
    this.punkteNoteSechs = punkteNoteSechs;
    this.punkteNoteVier = punkteNoteVier;
    this.summandMin = summandMin;
    this.summandMax = summandMax;
    this.minuendMin = minuendMin;
    this.minuendMax = minuendMax;
    this.subtrahendMin = subtrahendMin;
    this.subtrahendMax = subtrahendMax;
    this.faktorMin = faktorMin;
    this.faktorMax = faktorMax;
    this.divisorMin = divisorMin;
    this.divisorMax = divisorMax;
    this.numberOperation = numberOperation;

    // 1 für Addition, 2 für Subtraktion, 3 für Multiplikation 4 für Divsion
    let operation = Math.round(Math.random() * numberOperation + 0.5);
    // console.log('Operation' + operation);

    // nach Entscheid der Operation zwischen 1 und 4 werden je nach Operation zwei Zufallszahlen gebildet
    // bei der Subtraktion wird verhindert, dass das Resultat negativ wird (könnte noch als Option eingebaut werden )
    // bei der Division wird zuerst der Divisor gebildet, welcher am Ende mit dem Dividenden multipliziert wird, damit es eine Ganzzahl als Ergebnis gibt
    switch (operation) {
      case 1:
      let ersterSummand = Math.round(Math.random() * (summandMax - summandMin)) + summandMin;
      let zweiterSummand = Math.round(Math.random() * (summandMax - summandMin)) + summandMin;
        this.task = new ExerciseTask(ersterSummand, '+', zweiterSummand);
        this.ergebnis = ersterSummand + zweiterSummand;
      break;
      
      case 2:
        let minuend = Math.round(Math.random() * (minuendMax - minuendMin)) + minuendMin;
        let subtrahend = Math.round(Math.random() * (subtrahendMax - subtrahendMin)) + subtrahendMin;
        if (subtrahend > minuend) {
          let zwischenSpeicher = minuend;
          minuend = subtrahend;
          subtrahend = zwischenSpeicher;
        }
        this.task = new ExerciseTask(minuend, '-', subtrahend);
        this.ergebnis = minuend - subtrahend;
      break;
      
      case 3:
        let ersterFaktor = Math.round(Math.random() * (faktorMax - faktorMin) ) + faktorMin;
        let zweiterFaktor = Math.round(Math.random() * (faktorMax - faktorMin)) + faktorMin;
        this.task = new ExerciseTask(ersterFaktor, '*', zweiterFaktor);
        this.ergebnis = ersterFaktor * zweiterFaktor;
      break;
      
      case 4:
        let divisor = Math.round(Math.random() * (divisorMax - divisorMin)) + divisorMin;
        let dividend = Math.round(Math.random() * (divisorMax - divisorMin)) * divisor;
        this.task = new ExerciseTask(dividend, ':', divisor);
        this.ergebnis = dividend / divisor;
      break;
      
      default:
        alert("Keine Übereinstimmung");
    }
    
        
    // notwendige Werte werden im sessionStorage gespeichert
    // sessionStorage.setItem('aktuelle Punktzahl', JSON.stringify(this.actualPunkte));
    sessionStorage.setItem('aktuelle Nummer', JSON.stringify(this.actualTaskNumber));
             
  }

  // hier wird nach der Eingabe des Ergebnisses dieses nach click oder Return kontrolliert, die TaskNumber um 1 erhöht, und die nächste Aufgabe aufgerufen
  // zudem werden je nachdem Pluspunkte oder Minuspunkte verrechnet und die aktuelle Note neu berechnet
  // es wird ein Feedback gegeben, dies über 1,5 Sekunden, grün bei richtig gelöster Aufgabe, rot bei falsch gelöster Aufgabe
  public buttonClick() {
    this.lastArg1 = this.task.arg1;
	  this.lastOperand = this.task.operand;
	  this.lastArg2 = this.task.arg2;
    this.lastResultat = this.ergebnis;
    this.lastTextfield = this.textfield;
        
    if (parseFloat(this.textfield) === this.ergebnis) {

      this.actualPunkte = this.actualPunkte  + this.plusPunkte; //wird noch angepasst mit Pluspunkte, geht nicht wegen Typ undefined von Forms zum frei einstellen
      this.controlPunkte = this.controlPunkte + this.plusPunkte; 

      this.actualTaskNumber = this.actualTaskNumber + 1;
      this.feedback = 'Richtig + ' + this.plusPunkte +' Punkte';
      var node = document.querySelector('#richtig1') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#falsch1') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#richtig2') as HTMLElement;
      node.style.visibility = 'visible';
      var node = document.querySelector('#falsch2') as HTMLElement;
      node.style.visibility = 'hidden';

      sessionStorage.setItem('aktuelle Punkt', JSON.stringify(this.actualPunkte));
     
      setTimeout(this.ausblendenFeedback,1500);
      
      } else {
        this.actualPunkte = this.actualPunkte - this.minusPunkte; //wird noch angepasst mit minusPunkte, geht nicht wegen Typ undefined von Forms zum frei einstellen
        this.controlPunkte = this.controlPunkte - this.minusPunkte;
        this.actualTaskNumber = this.actualTaskNumber + 1;
        this.feedback = 'Falsch: - '  + this.minusPunkte +' Punkte';
        var node = document.querySelector('#richtig1') as HTMLElement;
        node.style.display = 'none';
        var node = document.querySelector('#falsch1') as HTMLElement;
        node.style.display = 'block';
        var node = document.querySelector('#richtig2') as HTMLElement;
        node.style.visibility = 'hidden';
        var node = document.querySelector('#falsch2') as HTMLElement;
        node.style.visibility = 'visible';

        sessionStorage.setItem('aktuelle Punkt', JSON.stringify(this.actualPunkte));
       
        setTimeout(this.ausblendenFeedback,1500);
      }
    this.createExerciseWrapper();
    this.textfield = '';
  }

  // Feedback wird nach einer bestimmten Zeit ausgeblendet
  public ausblendenFeedback() {
    var node = document.querySelector('#richtig1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#falsch1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#richtig2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#falsch2') as HTMLElement;
    node.style.visibility = 'hidden';
  }
  
  // hiermit wird die aktuelle Note berechnet
  public berechnungNote( ) {
       
    let note: number;
            
    // falls jemand Punkte über Maximum hat, soll Note 6 gelten
    if (this.controlPunkte > this.punkteNoteSechs) {
      note = 6;
      sessionStorage.setItem('definitive Note', JSON.stringify(note));     
    }
    // falls jemand Punkte unter Null hätte, soll Note 1 gelten
    else if (this.controlPunkte < 1) {
      note = 1;
      sessionStorage.setItem('definitive Note', JSON.stringify(note));    
    }
    // Punkte unter Punkte für Note sechs, aber über Punkte für Note vier
    else if (this.controlPunkte < this.punkteNoteSechs && this.actualPunkte > this.punkteNoteVier) {
      let note = (Math.round((this.controlPunkte - this.punkteNoteVier) * 2 / (this.punkteNoteSechs - this.punkteNoteVier) * 10) / 10) + 4;
      sessionStorage.setItem('definitive Note', JSON.stringify(note));     
    }
    // Punkte unter Punkte für Note vier, aber über 0 Punkte
    else if (this.controlPunkte < this.punkteNoteSechs && this.actualPunkte > 0) {
      let note = (Math.round((this.actualPunkte * 3 / this.punkteNoteVier) * 10) / 10) + 1;
      sessionStorage.setItem('definitive Note', JSON.stringify(note));  
    }
  }
 
}
