import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router } from '@angular/router';



@Component({
	selector: 'app-configuration-unterstufe',
	templateUrl: './configuration-unterstufe.component.html',
	styleUrls: ['./configuration-unterstufe.component.scss']
})
export class ConfigurationUnterstufeComponent implements OnInit {

	public numberOperation: number;
	public configurationUS: [];
		
	
	constructor( 
		private router: Router
	) {	}
		
	ngOnInit() {
				
		if ((document.querySelector('input[name=op]:checked').id))
		this.show1 = false;
		this.show2 = true;
		this.show3 = true;
		this.show4 = true;
		this.show5 = true;
		this.operations = false;
		this.exercise1 = true;
		this.exercise2 = true;
		this.exercise3 = true;
	}	

	
	
	show1 = false;
	show2 = true;
	show3 = true;
	show4 = true;
	show5 = true;
	operations = false;
	exercise1 = true;
	exercise2 = true;
	exercise3 = true;
		
	summandMin: number = 2;
	summandMax: number = 70;
	optionsAdd: Options = {
	  floor: 1,
	  ceil: 800
	}
	
	minuendMin: number = 2;
	minuendMax: number = 40;
	optionsSubt: Options = {
	  floor: 1,
	  ceil: 500,
	}
	
	faktorMin: number = 2;
	faktorMax: number = 10;
	optionsMult: Options = {
	  floor: 1,
	  ceil: 20,
	}
	
	divisorMin: number = 2;
	divisorMax: number = 6;
	optionsDiv: Options = {
	  floor: 1,
	  ceil: 50,
	};


	public showOne() {
	
	if ((document.querySelector('input[name=op]:checked').id))
		this.show1 = true;
		this.show2 = false;
		this.show3 = true;
		this.show4 = true;
		this.show5 = true;
		this.numberOperation = 1;		
	}

	public showTwo() {
	
	if ((document.querySelector('input[name=op]:checked').id))
		this.show1 = true;
		this.show2 = true;
		this.show3 = false;
		this.show4 = true;
		this.show5 = true;
		this.numberOperation = 2;
	}

	public showThree() {
	
	if ((document.querySelector('input[name=op]:checked').id))
		this.show1 = true;
		this.show2 = true;
		this.show3 = true;
		this.show4 = false;
		this.show5 = true;
		this.numberOperation = 3;
	}

	public showFour() {
	
	if ((document.querySelector('input[name=op]:checked').id))
		this.show1 = true;
		this.show2 = true;
		this.show3 = true;
		this.show4 = true;
		this.show5 = false;
		this.numberOperation = 4;
	}

	public exerciseUnterstufe() {
		const configurationUS = [
			this.numberOperation, 
			this.summandMin, 
			this.summandMax,
			this.minuendMin,
			this.minuendMax,
			this.faktorMin,
			this.faktorMax,
			this.divisorMin,
			this.divisorMax,
		];

		//	console.log('Konfiguration US: ' + configurationUS);
		sessionStorage.setItem("configurationUS", JSON.stringify(configurationUS));
		localStorage.setItem("configurationUS", JSON.stringify(configurationUS));		
		this.router.navigate(['/exercise-unterstufe']);
	
	  }
}  
 