import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ConfigurationComponent } from './configuration/normal/configuration.component';
import { ConfigurationUnterstufeComponent } from './configuration/configuration-unterstufe/configuration-unterstufe.component';
import { ConfigurationtestComponent } from './configuration/configurationtest/configurationtest.component';
import { Configurationtests2Component } from './configuration/configurationtests2/configurationtests2.component';
import { Configurationtestr2Component } from './configuration/configurationtestr2/configurationtestr2.component';
import { ExerciseComponent } from './exercise/normal/exercise.component';
import { TestclassComponent } from './exercise/exercisetest/exercisetest.component';
import { Testclasss2Component } from './exercise/exercisetests2/exercisetests2.component';
import { Exercisetestr2Component } from './exercise/exercisetestr2/exercisetestr2.component';
import { Exercistests3Component } from './exercise/exercistests3/exercistests3.component';
import { ResultsTestComponent } from './results/resultstest/resultstest.component';
import { Resultstests3Component } from './results/resultstests3/resultstests3.component';
import { Resultstests2Component } from './results/resultstests2/resultstests2.component';
import { Resultstestr2Component } from './results/resultstestr2/resultstestr2.component';
import { ExerciseUnterstufeComponent } from './exercise/exercise-unterstufe/exercise-unterstufe.component';
import { ResultsComponent } from './results/normal/results.component';
import { SettingstestComponent } from './settings/settingstest/settingstest.component';
import { Reihen1Component } from './reihen/reihen1/reihen1.component';
import { ZumtestenComponent } from './reihen/zumtesten/zumtesten.component';
import { Reihen2Component } from './reihen/reihen2/reihen2.component';
import { Reihen6Component } from './reihen/reihen6/reihen6.component';
import { InfoReihen6Component } from './results/reihen/reihen6/inforeihen6.component';
import { LevelsComponent } from './levels/levels.component';

// Varianten Counter, werden momentan nicht verwendet
import { NormalComponent } from './counter/normal/normal.component';
import { TestComponent } from './counter/test/test.component';
import { Testr2Component } from './counter/testr2/testr2.component';
import { Tests3Component } from './counter/tests3/tests3.component';
import { Tests2Component } from './counter/tests2/tests2.component';
import { UnterstufeComponent } from './counter/unterstufe/unterstufe.component';





const routes: Routes = [
  // Standardroute: Umleitung auf '/home'
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent, pathMatch: 'full'},
  { path: 'exercise', component: ExerciseComponent },
  { path: 'exercise-unterstufe', component: ExerciseUnterstufeComponent},
  { path: 'testclass', component: TestclassComponent },
  { path: 'testclasss2', component: Testclasss2Component },
  { path: 'testclassr2', component: Exercisetestr2Component},
  { path: 'testclasss3', component: Exercistests3Component },
  { path: 'configuration', component: ConfigurationComponent },
  { path: 'configurationtest', component: ConfigurationtestComponent },
  { path: 'configurationtests2', component: Configurationtests2Component },
  { path: 'configurationtestr2', component: Configurationtestr2Component },
  { path: 'configuration-unterstufe', component: ConfigurationUnterstufeComponent},
  { path: 'results', component: ResultsComponent},
  { path: 'resultstest', component: ResultsTestComponent},
  { path: 'resultstests3', component: Resultstests3Component},
  { path: 'resultstests2', component: Resultstests2Component},
  { path: 'resultstestr2', component: Resultstestr2Component},
  { path: 'settingstest', component: SettingstestComponent},
  { path: 'reihen1', component: Reihen1Component},
  { path: 'zumtesten', component: ZumtestenComponent},
  { path: 'reihen2', component: Reihen2Component},
  { path: 'reihen6', component: Reihen6Component},
  { path: 'inforeihen6', component: InfoReihen6Component},
  { path: 'levels', component: LevelsComponent},

  // Counter Varianten - momentan nicht verwendet
  { path: 'NormalComponent', component: NormalComponent},
  { path: 'TestComponent', component: TestComponent},
  { path: 'Testr2Component', component: Testr2Component},
  { path: 'Tests3Component', component: Tests3Component},
  { path: 'Tests2Component', component: Tests2Component},
  { path: 'UnterstufeComponent', component: UnterstufeComponent},
  
  


       
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
