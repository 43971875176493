import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Tests3Service } from '../../services/tests3.service';
import { FormType } from 'src/app/configuration/form-type';

@Component({
  selector: 'app-tests3',
  templateUrl: './tests3.component.html',
  styleUrls: ['./tests3.component.scss']
})
export class Tests3Component {

  private counter: number;
  private valuesAllgemein: FormType[]; 
  
  constructor(
    private router: Router,
    private Tests3Service: Tests3Service) { 
      

    }

    
    ngOnInit() {
      this.valuesAllgemein = this.Tests3Service.getConfig();
      this.counter = Number(this.valuesAllgemein[0].value)*60;
  //    this.startCounter();
  //    this.startBalken();
     }

  // Countdown Uhr starten
  private startCounter() {
    var i = this.counter;
    var myinterval = setInterval(() => { 
      // Umrechnung der Sekunden in Minuten und Sekunden
      var  timenew = Math.floor(i / 60 )+' : '+Math.floor((i % 60));
      document.getElementById("counter").innerHTML = timenew;
      if (i === 0) {
        clearInterval(myinterval );
        this.router.navigate(['/results']);
      }
      else {
        i--;
      }
    }, 1000);
  }

// Countdown Balken starten, Progress negativ 
public startBalken() {
  var i = 0;
  if (i == 0) {
    var elem = document.getElementById("balken");
    var width = 100;
    var faktor = this.counter/100*1000;
    var id = setInterval(frame, faktor);
    function frame() {
      if (width <=0) {
        clearInterval(id);
       i = 0 ;
      } else {
        width--;
        elem.style.width = width + "%";
        var node = document.querySelector('#balken') as HTMLElement;
        node.style.display = 'block';
        }
      }
    }
  }
}   

