import { Component, OnInit } from '@angular/core';
import { FormType } from '../form-type';
import { Router, Params } from '@angular/router';
import { TestService } from '../../services/test.service';



@Component({
  selector: 'app-configurationtest',
  templateUrl: './configurationtest.component.html',
  styleUrls: ['./configurationtest.component.scss']
})
export class ConfigurationtestComponent implements OnInit {

 
  public values: FormType[];

  
  constructor(
    private router: Router,
    private testService: TestService) { }
   

  ngOnInit() {
    this.values = this.testService.getConfig();
  }

 
  public buttonClick() {
    this.testService.saveConfig(this.values);
    // console.log('ValuesBi: ' + JSON.stringify(this.values));

    this.router.navigate(['/testclass']);
    
  }

}
