import { Component, OnInit } from '@angular/core';
import { FormType } from '../../configuration/form-type';
import { Tests3Service } from '../../services/tests3.service';

@Component({
  selector: 'app-settingstests3',
  templateUrl: './settingstests3.component.html',
  styleUrls: ['./settingstests3.component.scss']
})
export class Settingstests3Component implements OnInit {

  public counter: number;
  public name: string;
  public plusPunkte: number;
  public minusPunkte: number;
  public punkteNoteSechs: number;
  public punkteNoteVier: number;
  public summandMin: number;
  public summandMax: number;
  public minuendMin: number;
  public minuendMax: number;
  public subtrahendMin: number;
  public subtrahendMax: number;
  public faktorMin: number;
  public faktorMax: number;
  public divisorMin: number;
  public divisorMax: number;
  public numberOperation: number;
 

  public valuesSettings: FormType[]; 

  constructor(
    private Tests3Service: Tests3Service
  ) { }

  ngOnInit() {
    this.valuesSettings = this.Tests3Service.getConfig();
  
    
    this.counter = Number(this.valuesSettings[0].value);
    this.plusPunkte = Number(this.valuesSettings[1].value);
    this.minusPunkte = Number(this.valuesSettings[2].value);
    this.punkteNoteSechs = Number(this.valuesSettings[3].value);
    this.punkteNoteVier = Number(this.valuesSettings[4].value);
    this.summandMin = Number(this.valuesSettings[5].value);
    this.summandMax = Number(this.valuesSettings[6].value);
    this.minuendMin = Number(this.valuesSettings[7].value);
    this.minuendMax = Number(this.valuesSettings[8].value);
    this.subtrahendMin = Number(this.valuesSettings[9].value);
    this.subtrahendMax = Number(this.valuesSettings[10].value);
    this.faktorMin = Number(this.valuesSettings[11].value);
    this.faktorMax = Number(this.valuesSettings[12].value);
    this.divisorMin = Number(this.valuesSettings[13].value);
    this.divisorMax = Number(this.valuesSettings[14].value);
    this.numberOperation = Number(this.valuesSettings[15].value);
           
  }

}

