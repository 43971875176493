import { Component, OnInit, ViewChild } from '@angular/core';
import { FormType } from '../form-type';
import { Router, Params } from '@angular/router';
import { Testr2Service } from '../../services/testr2.service';

@Component({
  selector: 'app-configurationtestr2',
  templateUrl: './configurationtestr2.component.html',
  styleUrls: ['./configurationtestr2.component.scss']
})
export class Configurationtestr2Component implements OnInit {

  public values: FormType[];

  constructor(
    private router: Router,
    private testr2Service: Testr2Service) { }


  ngOnInit() {
    this.values = this.testr2Service.getConfig();
  }

 
  public buttonClick() {
    this.testr2Service.saveConfig(this.values);
    // console.log('ValuesBi: ' + JSON.stringify(this.values));

    this.router.navigate(['/testclassr2']);
    
  }

}


