import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inforeihen6',
  templateUrl: './inforeihen6.component.html',
  styleUrls: ['./inforeihen6.component.scss']
})
export class InfoReihen6Component implements OnInit {

  constructor(
    private router: Router
  ) {   }

  ngOnInit(): void {
    // Name, Aufgaben und Punkte von sessionStorage verwenden
    document.getElementById("reihe").innerHTML = sessionStorage.getItem('aktuelle Reihe');
    document.getElementById("anzahl").innerHTML = sessionStorage.getItem('Anzahl Aufgaben');
    document.getElementById("punkte").innerHTML = sessionStorage.getItem('aktuelle Punktzahl');
  }

  public level6(){
    this.router.navigate(['/reihen6']);
  }

}
