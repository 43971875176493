import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-zumtesten',
  templateUrl: './zumtesten.component.html',
  styleUrls: ['./zumtesten.component.scss']
})
export class ZumtestenComponent  {

  faktor1 = 1;
  reihe = 4;

  resultate = [
    this.faktor1 * this.reihe,
    (this.faktor1+1) * this.reihe,
    (this.faktor1+2) * this.reihe,
    (this.faktor1+3) * this.reihe,
    (this.faktor1+4) * this.reihe,
    (this.faktor1+5) * this.reihe,
    (this.faktor1+6) * this.reihe,
    (this.faktor1+7) * this.reihe,
    (this.faktor1+8) * this.reihe,
    (this.faktor1+9) * this.reihe,
  ];
  
  rechnungen = [
    this.faktor1 + ' mal '+ this.reihe,
    this.faktor1+1 + ' mal '+ this.reihe,
    this.faktor1+2 + ' mal '+ this.reihe,
    this.faktor1+3 + ' mal '+ this.reihe,
    this.faktor1+4 + ' mal '+ this.reihe,
    this.faktor1+5 + ' mal '+ this.reihe,
    this.faktor1+6 + ' mal '+ this.reihe,
    this.faktor1+7 + ' mal '+ this.reihe,
    this.faktor1+8 + ' mal '+ this.reihe,
    this.faktor1+9 + ' mal '+ this.reihe,
  ];

  zielbereich = [
    ' .. ',' .. ',' .. ',' .. ',' .. ',' .. ',' .. ',' .. ',' .. ',' .. '
    
  ];
  
 

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.resultate, event.previousIndex, event.currentIndex);
  }
  




  

 

  constructor() { }

  ngOnInit(): void {
    console.log('im Zielbereich1: ',  this.zielbereich[0])
  }

}
