import { Component, OnInit } from '@angular/core';
import { FormType } from '../../configuration/form-type';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-settingsnormal',
  templateUrl: './settingsnormal.component.html',
  styleUrls: ['./settingsnormal.component.scss']
})

export class SettingsnormalComponent implements OnInit {

  public counter: number;
  public name: string;
  public summandMin: number;
  public summandMax: number;
  public minuendMin: number;
  public minuendMax: number;
  public subtrahendMin: number;
  public subtrahendMax: number;
  public faktorMin: number;
  public faktorMax: number;
  public divisorMin: number;
  public divisorMax: number;
  public numberOperation: number;
 

  public valuesSettings: FormType[]; 

  constructor(
    private CoreService: CoreService
  ) { }

  ngOnInit() {
    this.valuesSettings = this.CoreService.getConfig();
  
    this.counter = Number(this.valuesSettings[0].value)*60;
    this.name = String(this.valuesSettings[1].value);
    this.summandMin = Number(this.valuesSettings[2].value);
    this.summandMax = Number(this.valuesSettings[3].value);
    this.minuendMin = Number(this.valuesSettings[4].value);
    this.minuendMax = Number(this.valuesSettings[5].value);
    this.subtrahendMin = Number(this.valuesSettings[6].value);
    this.subtrahendMax = Number(this.valuesSettings[7].value);
    this.faktorMin = Number(this.valuesSettings[8].value);
    this.faktorMax = Number(this.valuesSettings[9].value);
    this.divisorMin = Number(this.valuesSettings[10].value);
    this.divisorMax = Number(this.valuesSettings[11].value);
    this.numberOperation = Number(this.valuesSettings[12].value);
           
  }

}
