import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExerciseConfig } from '../interfaces/exercise-config';
import { FormType } from '../configuration/form-type';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private defaultValues: FormType[];


  constructor(private http: HttpClient) {
    this.defaultValues = [];
    this.defaultValues.push(new FormType('Wie lange willst du üben? (Anzahl Minuten)', "10"));
    this.defaultValues.push(new FormType('Gib hier deinen Namen ein', "NoName"));
    this.defaultValues.push(new FormType('Wie gross sollen die Summanden mindestens sein?', "10"));
    this.defaultValues.push(new FormType('Wie gross dürfen die Summanden höchstens sein?', "400"));
    this.defaultValues.push(new FormType('Wie gross soll der Minuend mindestens sein?', "30"));
    this.defaultValues.push(new FormType('Wie gross darf der Minuend höchstens sein?', "300"));
    this.defaultValues.push(new FormType('Wie gross darf der Subtrahend mindestens sein?', "5"));
    this.defaultValues.push(new FormType('Wie gross darf der Subtrahend höchstens sein?', "60"));
    this.defaultValues.push(new FormType('Wie gross dürfen die Faktoren mindestens sein?', "3"));
    this.defaultValues.push(new FormType('Wie gross dürfen die Faktoren höchstens sein?', "20"));
    this.defaultValues.push(new FormType('Wie gross darf der Divisor mindestens sein?', "3"));
    this.defaultValues.push(new FormType('Wie gross darf der Divisor höchstens sein?', "20"));
    this.defaultValues.push(new FormType('alle(4), ohne Div(3), Add/Subt(2), nur Add(1), nur Subt(5), nur Mult(6), nur Div(7)',  "4"));
}

  public getExercise(): Promise<ExerciseConfig> {
    return this.http.get<ExerciseConfig>('/assets/exercise-config.json').toPromise();
  }

  public saveConfig(values: FormType[]) {
    sessionStorage.setItem('values', JSON.stringify(values));
    
  }

  public getConfig(): FormType[] {
    // tslint:disable-next-line: prefer-const
    let values = sessionStorage.getItem('values');
    if (!isNullOrUndefined(values) && values.length > 0) {
      return JSON.parse(values) as FormType[];
    }
    return this.defaultValues;
  }
}
